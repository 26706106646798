import {createTheme} from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#154734",
        },
    },
    background: {
        default: "#fff"
    }
});

export default theme;