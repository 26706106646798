import {ThemeProvider} from "@mui/material";
import theme from "../styles/theme";
import "../styles/single.css";
import "../styles/sidebar.css";
import "../styles/singlePost.css";
import "../styles/topbar.css";
import "../styles/globals.css";
import MyAppBar from "../components/MyAppBar";
import {Toaster} from "react-hot-toast";
import Script from "next/script";

function MyApp({Component, pageProps}) {
    return (
        <ThemeProvider theme={theme}>
            <Script strategy="lazyOnload" src={`https://www.googletagmanager.com/gtag/js?id=G-MD28DB3N5Z`}/>

            <Script strategy="lazyOnload" id="my-google-analytics">
                {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-MD28DB3N5Z', {
                    page_path: window.location.pathname,
                    });
                `}
            </Script>
            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    // Define default options
                    className: '',
                    duration: 5000,
                    style: {
                        background: '#363636',
                        color: '#fff',
                    },

                    // Default options for specific types
                    success: {
                        duration: 3000,
                        theme: {
                            primary: 'green',
                            secondary: 'black',
                        },
                    },
                }}
            />
            <MyAppBar/>
            <Component {...pageProps} />
        </ThemeProvider>
    );
}

export default MyApp
